// CSS Style
import "@fontsource/comfortaa/300.css"
import "@fontsource/comfortaa/400.css"
import "@fontsource/comfortaa/700.css"
import 'admin/styles/site.scss'
import 'admin/styles/style.min.css'
import 'admin/styles/custom.scss'
import 'public/custom_editor.scss'
import 'c3/c3.css'
import 'admin/styles/c3.scss'

// Other JS
import('admin/site')
import 'admin/notification'
import 'public/js/tw-city-selector';
import SyncSelect from 'public/js/sync-select';
import 'select2';

import * as ActiveStorage from "@rails/activestorage"
import introJs from 'intro.js'
import c3 from 'c3'

// import "channels"
ActiveStorage.start()

// 因為在 webpack 打包的時候，沒有瀏覽器的 window 物件，所以必須 assign 這樣才能在 rails view 使用
// Ref: https://kaochenlong.com/2019/11/22/webpacker-with-rails-part-2/
window.jQuery = $
window.$ = $
window.c3 = c3
window.introJs = introJs
window.SyncSelect = SyncSelect
